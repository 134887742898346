import { HttpClient } from '@angular/common/http';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn, Router } from '@angular/router';
import { of } from 'rxjs';
import { createApiUrl, traverseObjectToFindKeyInData } from '../../../apex/src/app/utils/functions';
import { PublicCustomer } from './app.types';

export const PublicCustomersResolver: ResolveFn<PublicCustomer[]> = () => {
  const http = inject(HttpClient);

  return http.get<PublicCustomer[]>(createApiUrl('public', 'requests', 'customer'), {
    withCredentials: false,
  });
};

export const PublicCustomerResolver: ResolveFn<PublicCustomer> = (route: ActivatedRouteSnapshot) => {
  const customers = traverseObjectToFindKeyInData<PublicCustomer[]>(route, 'customers');
  const router = inject(Router);
  const subdomain = route.params.subdomain;

  if (!customers || !subdomain) {
    void router.navigate(['/']);

    return of(null);
  }

  const customer = customers.find((c) => c.subdomain === subdomain);

  if (!customer) {
    void router.navigate(['/']);

    return of(null);
  }

  return of(customer);
};
