import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { PageNotFoundComponent } from 'projects/apex/src/app/components/page-not-found/page-not-found.component';
import { IndexComponent } from './index/index.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { PublicCustomerResolver, PublicCustomersResolver } from './public-customers.resolver';
import { RequestCreatedComponent } from './request-created/request-created.component';

const routes: Routes = [
  {
    path: '',
    resolve: {
      customers: PublicCustomersResolver,
    },
    children: [
      {
        path: '',
        component: IndexComponent,
      },
      {
        path: ':subdomain',
        resolve: {
          customer: PublicCustomerResolver,
        },
        children: [
          {
            path: '',
            component: NewRequestComponent,
          },
          {
            path: 'created',
            component: RequestCreatedComponent,
          },
        ],
      },
      {
        path: '**',
        component: PageNotFoundComponent,
      },
    ],
  },
  {
    path: '**',
    component: PageNotFoundComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
  providers: [],
})
export class AppRoutingModule {}
