import { Component, OnInit } from '@angular/core';

import { tx } from '@transifex/native';
import { locale } from 'moment';
import { localeInitializer } from 'projects/apex/src/app/utils/locale-init';
import { TranslationService } from '../../../apex/src/app/components/translate/translation.service';
import { environment } from '../../../apex/src/environments/environment';

@Component({
  selector: 'requests-root',
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {
  constructor(private translationService: TranslationService) {
    void localeInitializer(locale());

    void tx.init({
      token: environment.transifexToken,
    });
  }

  async ngOnInit(): Promise<void> {
    try {
      await this.translationService.setCurrentLocale(locale());
    } catch (err) {
      console.error('Failed to get translations');
    }
  }
}
