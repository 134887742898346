<apex-page-info>
  <T
    title
    str="Request created"></T>
</apex-page-info>

<div class="ap ama max-width-640px">
  <div class="ap mat-elevation-z2">
    <h1>
      <T str="Thanks!"></T>
    </h1>

    <p>
      <T str="Your request has been created."></T>
    </p>

    <div class="amt df jfe">
      <a
        mat-raised-button
        color="primary"
        [routerLink]="['..']">
        <T str="Create another request"></T>
      </a>
    </div>
  </div>
</div>
