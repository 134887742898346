import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { ErrorStateMatcher, ShowOnDirtyErrorStateMatcher } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatTableModule } from '@angular/material/table';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { BoxModule } from '../../../apex/src/app/components/box/box.module';
import { PageInfoModule } from '../../../apex/src/app/components/page-info/page-info.module';
import { TranslateModule } from '../../../apex/src/app/components/translate/translate.module';
import { RequestStatusInterceptor } from '../../../apex/src/app/interceptors/status/request-status.interceptor';
import StatusServiceModule from '../../../apex/src/app/interceptors/status/status.service.module';
import { SnackModule } from '../../../apex/src/app/modules/snack.module';
import { UserService } from '../../../apex/src/app/services/user/user.service';
import { RaygunErrorHandler } from '../../../apex/src/app/utils/error-handler';
import { LocaleProvider } from '../../../apex/src/app/utils/locale-provider';
import { environment } from '../../../apex/src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { IndexComponent } from './index/index.component';
import { NewRequestComponent } from './new-request/new-request.component';
import { RequestCreatedComponent } from './request-created/request-created.component';

@NgModule({
  declarations: [AppComponent, IndexComponent, NewRequestComponent, RequestCreatedComponent],
  imports: [
    AppRoutingModule,

    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,

    TranslateModule,

    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatTableModule,
    MatProgressSpinnerModule,
    MatSelectModule,
    MatDialogModule,

    SnackModule,
    StatusServiceModule,
    PageInfoModule,
    BoxModule,
  ],
  providers: [
    HttpClient,
    {
      provide: ErrorStateMatcher,
      useClass: ShowOnDirtyErrorStateMatcher,
    },
    LocaleProvider,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestStatusInterceptor,
      multi: true,
    },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler,
    },
    {
      provide: TINYMCE_SCRIPT_SRC,
      useValue: `${environment.appUrl}/tinymce/tinymce.min.js`,
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'outline',
      },
    },
    UserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
