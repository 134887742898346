import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PublicCustomer } from '../app.types';

@Component({
  selector: 'requests-request-created',
  templateUrl: './request-created.component.html',
})
export class RequestCreatedComponent {
  customer: PublicCustomer;

  constructor(private route: ActivatedRoute) {
    this.customer = this.route.snapshot.data.customer as PublicCustomer;
  }
}
