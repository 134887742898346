/// <reference types="grecaptcha">

import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subject, of } from 'rxjs';
import { finalize, mergeMap } from 'rxjs/operators';
import { IDN } from '../../../../apex/src/app/features/reporting/project-cases-by-day/project-cases-by-day.types';
import { Request } from '../../../../apex/src/app/models/request';
import { createApiUrl } from '../../../../apex/src/app/utils/functions';
import { PublicCustomer } from '../app.types';

const emptyRequest = (): Partial<Request> => ({
  clientName: '',
  clientMail: '',
  clientPhone: '',
  clientAddress: '',

  caseName: '',
  description: '',

  ProjectId: undefined,
  ApartmentId: undefined,
});

type IDLNR = {
  id: number;
  leilighetsnr: string;
};

@Component({
  selector: 'requests-new-request',
  templateUrl: 'new-request.component.html',
})
export class NewRequestComponent implements OnInit {
  customer: PublicCustomer;
  projects$: Observable<IDN[]>;
  apartments$: Observable<IDLNR[]>;

  projectId$ = new Subject<number>();

  newRequest: Partial<Request> = emptyRequest();
  saving = false;

  private reCaptchaWidgetId: number;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.customer = this.route.snapshot.data.customer as PublicCustomer;

    this.projects$ = this.http.get<IDN[]>(createApiUrl('public', 'requests', 'customer', this.customer.id, 'project'), {
      withCredentials: false,
    });

    this.apartments$ = this.projectId$.pipe(
      mergeMap((projectId) => {
        if (projectId) {
          return this.http.get<IDN[]>(
            createApiUrl(
              'public',
              'requests',
              'customer',
              this.customer.id,
              'project',
              this.newRequest.ProjectId,
              'apartment',
            ),
            {
              withCredentials: false,
            },
          );
        } else {
          return of([]);
        }
      }),
    );
  }

  async ngOnInit(): Promise<void> {
    grecaptcha.render('recaptchaDiv', {
      sitekey: '6LfkdRgUAAAAAJmbELS3bFNIZ_dVNBJrMhabueOH',
      callback: (recaptchaCode) => this.saveRequest(recaptchaCode),
      size: 'invisible',
      'error-callback': () => {
        this.saving = false;
      },
    });
  }

  submit(): void {
    this.newRequest.CustomerId = this.customer.id;

    void grecaptcha.execute(this.reCaptchaWidgetId);
  }

  saveRequest(recaptchaCode: string): void {
    grecaptcha.reset(this.reCaptchaWidgetId);

    this.http
      .post<Request>(
        createApiUrl('public', 'request'),
        {
          ...this.newRequest,
          recaptchaCode,
        },
        {
          withCredentials: false,
        },
      )
      .pipe(
        finalize(() => {
          this.saving = false;
        }),
      )
      .subscribe({
        next: () => {
          void this.router.navigate(['/', this.customer.subdomain, 'created']);
        },
      });
  }
}
