<apex-page-info>
  <T
    title
    str="New request"></T>
</apex-page-info>

<ng-container *ngIf="customer">
  <div class="ap ama max-width-640px">
    <apex-box
      expanded
      [expandable]="false">
      <T
        title
        str="Send a new request to {customerName}"
        [vars]="{ customerName: customer.name }"></T>

      <div class="ambs aps">
        <T
          str="Fill in the required fields below to send in your request. You can select a project and residence if you know these.">
        </T>
      </div>

      <form
        #newRequestForm="ngForm"
        (submit)="submit()">
        <div class="aphs df c">
          <mat-form-field>
            <mat-label>
              <T str="Name"></T>
            </mat-label>
            <input
              matInput
              name="name"
              required
              minlength="3"
              [(ngModel)]="newRequest.clientName" />
            <mat-error>
              <T str="Name is required"></T>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="Email"></T>
            </mat-label>
            <input
              matInput
              type="email"
              name="email"
              email
              required
              [(ngModel)]="newRequest.clientMail" />
            <mat-error>
              <T str="A valid email is required"></T>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="Phone"></T>
            </mat-label>
            <input
              matInput
              type="number"
              name="phone"
              [(ngModel)]="newRequest.clientPhone" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="Address"></T>
            </mat-label>
            <input
              matInput
              name="address"
              [(ngModel)]="newRequest.clientAddress" />
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="Case name"></T>
            </mat-label>
            <input
              matInput
              name="caseName"
              required
              minlength="5"
              [(ngModel)]="newRequest.caseName" />
            <mat-error>
              <T str="Case name is required"></T>
            </mat-error>
          </mat-form-field>

          <mat-form-field>
            <mat-label>
              <T str="Description"></T>
            </mat-label>
            <textarea
              matInput
              name="description"
              required
              minlength="5"
              rows="5"
              [(ngModel)]="newRequest.description"></textarea>
            <mat-error>
              <T str="Description is required"></T>
            </mat-error>
          </mat-form-field>

          <ng-container *ngIf="projects$ | async as projects">
            <mat-form-field>
              <mat-label>
                <T str="Project"></T>
              </mat-label>
              <mat-select
                name="ProjectId"
                [(ngModel)]="newRequest.ProjectId"
                [disabled]="!projects.length"
                (ngModelChange)="projectId$.next($event); newRequest.ApartmentId = ''">
                <mat-option [value]="">
                  <T str="None"></T>
                </mat-option>
                <mat-option
                  *ngFor="let project of projects"
                  [value]="project.id">
                  {{ project.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field>
              <mat-label>
                <T str="Residence"></T>
              </mat-label>
              <mat-select
                name="ApartmentId"
                [(ngModel)]="newRequest.ApartmentId"
                [disabled]="!projects.length">
                <mat-option [value]="">
                  <T str="None"></T>
                </mat-option>
                <ng-container *ngIf="apartments$ | async as apartments">
                  <mat-option
                    *ngFor="let apartment of apartments"
                    [value]="apartment.id">
                    {{ apartment.leilighetsnr }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </ng-container>
        </div>
        <div class="ams df jfe">
          <button
            type="submit"
            mat-raised-button
            color="primary"
            [disabled]="!newRequestForm.valid || !newRequestForm.touched">
            <T
              str="Create request"
              context="action"
              _context="action">
            </T>
          </button>
        </div>
      </form>
    </apex-box>
  </div>
</ng-container>

<div id="recaptchaDiv"></div>
